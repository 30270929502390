<template>
  <ContactModule />
</template>

<script>
import ContactModule from "@/modules/Contact";
export default {
  name: "Contact",
  components: {
    ContactModule,
  },
  setup() {},
};
</script>

<style scoped lang="scss"></style>
