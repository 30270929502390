<template>
  <section class="termsOfServiceRoot">
    <div class="container">
      <h2>Definitions</h2>
      <p>
        <b>Customer:</b> any professional or capable natural person within the
        meaning of Articles 1123 et seq. of the Civil Code, or legal entity, who
        visits the Site subject of these general conditions.<br />
        <b>Provisions and Services:</b>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> provides
        Customers:
      </p>

      <p>
        <b>Content:</b> All elements constituting the information present on the
        Site, in particular texts - images - videos.
      </p>

      <p>
        <b>Customer information:</b> Hereinafter referred to as "Information
        (s)" which correspond to all personal data that may be held by
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> for the management
        of your account, the management of customer relations and for analysis
        and statistical purposes.
      </p>

      <p>
        <b>User:</b> Internet user connecting, using the site above-mentioned
        site.
      </p>
      <p>
        <b>Personal information:</b> "Information that allows, in any form
        whatsoever, directly or not, the identification of the natural persons
        to whom they apply" (article 4 of the law n° 78-17 of January 6, 1978).
      </p>
      <p>
        The terms "personal data", "data subject", " sub-processor" and
        "sensitive data" have the meaning defined by the General Data Protection
        Regulation (GDPR: No. 2016-679).
      </p>

      <h2>1. Presentation of the website.</h2>
      <p>
        Pursuant to Article 6 of Law No. 2004-575 of 21 June 2004 for the
        confidence in the digital economy, it is specified to the users of the
        website
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> the identity of
        the various parties involved in its realization and monitoring:
      </p>
      <p>
        <strong>Owner</strong>: Bastien Rigaud - 14 Rue Albert Pitres 33000
        Bordeaux<br />

        <strong>Publication Manager</strong>: Bastien Rigaud -
        contact@bastienrigaud.fr<br />
        The person responsible for publication is a natural person or a legal
        entity. legal entity.<br />
        <strong>Webmaster</strong> : Bastien Rigaud -
        contact@bastienrigaud.fr<br />
        <strong>Host</strong>: ovh - 2 rue Kellermann 59100 Roubaix 1007<br />
        <strong>Data Protection Officer</strong> : Bastien Rigaud -
        contact@bastienrigaud.fr<br />
      </p>

      <div ng-bind-html="linkHTML">
        <p>
          The legal notices are taken from the template proposed by the
          <a
            href="https://fr.orson.io/1371/generateur-mentions-legales"
            title="free generator offered by Orson.io"
            >free generator offered by Orson.io</a
          >
        </p>
      </div>

      <h2>
        2. Terms and conditions of use of the site and the services offered.
      </h2>

      <p>
        The Site constitutes a work of the mind protected by the provisions of
        the Code of Intellectual Property and the applicable International
        Regulations. The Customer may not in any way reuse, transfer or exploit
        for its own account all or part of the elements or works of the Site.
      </p>

      <p>
        Use of the Site
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> implies full and
        complete acceptance of the general terms of use described below. These
        conditions of use may be modified or completed at any time modified or
        completed at any time, the users of the site
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> are therefore
        invited to consult them on a regular basis.
      </p>

      <p>
        This website is normally accessible at any time to users. An
        interruption for technical maintenance reasons may be decided by be
        however decided by
        <a href="https://www.teyz.fr">https://www.teyz.fr</a>, which will
        endeavor to to inform users in advance of the dates and times of the
        intervention. of the intervention. The website
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> is updated
        regularly by
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> responsible. In
        the same way, the Similarly, the legal notices may be modified at any
        time: they are nevertheless binding on the user who is invited to refer
        to them as often as possible in order to read them.
      </p>

      <h2>3. Description of services provided.</h2>

      <p>
        The <a href="https://www.teyz.fr">https://www.teyz.fr</a> website has
        purpose is to provide information about all of the company's company's
        activities.
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> strives to provide
        on the site
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> information as
        accurate as possible. However, it cannot be held responsible for
        omissions, inaccuracies and shortcomings in the updating of the for
        omissions, inaccuracies or failures to update, whether caused by the
        company or third provide it with this information.
      </p>

      <p>
        All information indicated on the site
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> is given as an
        indication and is subject to change. indicative, and are subject to
        change. In addition, the information contained on the site
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> is not exhaustive.
        It is given subject to modifications having been made since it was put
        online.
      </p>

      <h2>4. Contractual limitations on technical data.</h2>

      <p>
        The website uses JavaScript technology. The website will not be held
        responsible for any material damage related to the use of the site. In
        addition, the user of the site agrees to access the site by using recent
        equipment, free of viruses and with a last generation updated browser.
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> is hosted by
        service provider in the European Union in accordance with the provisions
        of the provisions of the General Data Protection Regulation (RGPD: n°
        2016-679)
      </p>

      <p>
        The objective is to provide a service that ensures the best rate
        accessibility. The host ensures the continuity of its service 24 Hours a
        day, every day of the year. However, it reserves the right to the
        possibility of interrupting the hosting service for the shortest
        possible time possible, in particular for maintenance purposes,
        improvement of its infrastructure, failure of its infrastructure or if
        the infrastructure or if the Services generate a traffic deemed
        abnormal. deemed abnormal.
      </p>

      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> and the Host shall
        not be held responsible in the event of malfunction of the Internet
        network, telephone lines Internet, telephone lines or computer and
        telephony equipment, in particular due to telephone lines or computer
        and telephony equipment, particularly in the event of network congestion
        preventing access to the server.
      </p>

      <h2>5. Intellectual property and counterfeiting.</h2>

      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> owns of the
        intellectual property rights and holds the rights of use on all elements
        accessible on the website, including text, images, graphics, logos,
        videos, icons and sounds. Any reproduction, representation,
        modification, publication, adaptation of all or part of all or part of
        the elements of the site, whatever the means or process used, is
        prohibited, except with the prior written permission of :
        <a href="https://www.teyz.fr">https://www.teyz.fr</a>.
      </p>

      <p>
        Any unauthorized use of the site or of any of the elements it contains
        will be considered as constitutive of an infringement and and prosecuted
        in accordance with the provisions of Articles L.335-2 and following of
        the Intellectual Property Code.
      </p>

      <h2>6. Limitations of liability.</h2>

      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> acts as publisher
        of the site. <a href="https://www.teyz.fr">https://www.teyz.fr</a> is
        responsible for the quality and truthfulness of the Content it
        publishes.
      </p>

      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> shall not be be
        held responsible for any direct or indirect damage caused to the user's
        equipment the user, when accessing the website
        <a href="https://www.teyz.fr">https://www.teyz.fr</a>, and resulting
        either the use of equipment that does not meet the specifications
        indicated in point 4, or the appearance of a bug or an incompatibility.
      </p>

      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> shall not be held
        liable for any indirect damages (such as loss of business or loss of
        opportunity) such as loss of market or loss of opportunity) resulting
        from the use of the site
        <a href="https://www.teyz.fr">https://www.teyz.fr</a>. Interactive
        interactive spaces (possibility of asking questions in the contact
        space) are available to users.
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> reserves the the
        right to delete, without prior notice, any content posted in this area
        that in this space that would contravene the legislation applicable in
        France France, in particular to the provisions relating to the
        protection of data protection. If applicable,
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> reserves also
        reserves the possibility of calling into question the civil and/or
        criminal liability and/or criminal liability of the user, in particular
        in the event of messages of a racist racist, insulting, defamatory, or
        pornographic, whatever the medium used (text, photography ...).
      </p>

      <h2>7. Management of personal data.</h2>

      <p>
        The Customer is informed of the regulations concerning the communication
        marketing, the law of 21 June 2014 for confidence in the Digital Economy
        Economy, the Data Protection Act of August 6, 2004 and the General
        General Data Protection Regulation (RGPD: n° 2016-679).
      </p>

      <h3>7.1 Persons responsible for the collection of personal data</h3>

      <p>
        For the Personal Data collected in the context of the creation of the
        personal account of the User and his navigation on the Site, the
        responsible for processing Personal Data is: Bastien Rigaud.
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> is represented by
        Bastien Rigaud, its legal representative
      </p>

      <p>
        As the person responsible for processing the data it collects,
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> undertakes to
        respect the framework of the legal provisions in force. It is the
        Customer to establish the purposes of its data processing, to provide
        its data processing, to provide its prospects and customers, from the
        collection of their their consent, complete information on the
        processing of their personal data and of their personal data and to
        maintain a register of the processing in accordance with and to maintain
        an accurate record of the processing. Whenever
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> processes Personal
        Data Personal Data,
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> takes all
        reasonable steps to ensure the accuracy and relevance of Personal Data
        to the Personal Data with respect to the purposes for which
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> processes it.
      </p>

      <h3>7.2 Purpose of the data collected</h3>

      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> is likely to
        process all or part of the data:
      </p>

      <ul>
        <li>
          to enable navigation on the Site and the management and traceability
          of services ordered by the user: connection and usage data of the
          Site, billing, order history orders, etc.
        </li>

        <li>
          to prevent and fight against computer fraud (spamming, hacking...):
          computer equipment used for navigation, IP address, password (hashed)
        </li>

        <li>
          to improve navigation on the Site: connection data and usage data
        </li>

        <li>
          To conduct optional satisfaction surveys on
          <a href="https://www.teyz.fr">https://www.teyz.fr</a>: email address
        </li>
        <li>
          to conduct communication campaigns (sms, email): phone number phone
          number, email address
        </li>
      </ul>

      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> does not market
        your personal data, which are therefore only used by necessity or for
        statistical necessity or for statistical and analytical purposes.
      </p>

      <h3>7.3 Right of access, rectification and opposition</h3>

      <p>
        In accordance with current European regulations, Users of
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> have the following
        rights:
      </p>
      <ul>
        <li>
          right of access (Article 15 GDPR) and rectification (Article 16 GDPR),
          update, completeness of Users' data right to blocking or deletion of
          Users' data of a personal nature (Article 17 RGPD) personal data
          (article 17 RGPD), when they are inaccurate incomplete, equivocal,
          outdated, or whose collection, use, communication communication or
          storage is prohibited
        </li>

        <li>
          Right to withdraw consent at any time (Article 13-2c GDPR).
        </li>

        <li>
          Right to limit the processing of Users' data. (Article 18 RGPD)
        </li>

        <li>
          right to object to the processing of Users' data (Art. 21 RGPD)
        </li>

        <li>
          right to portability of data that Users will have provided, where such
          data are subject to automated processing based on their consent or on
          a contract (Article 20 RGPD)
        </li>

        <li>
          Right to define the fate of Users' data after their death and to
          choose to whom
          <a href="https://www.teyz.fr">https://www.teyz.fr</a> shall
          communicate (or not) their data to a third party they have previously
          designated
        </li>
      </ul>

      <p>
        As soon as <a href="https://www.teyz.fr">https://www.teyz.fr</a> has
        knowledge of a User's death and in the absence of instructions from the
        User, the from him,
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> undertakes to
        destroy his/her data, except if its conservation is necessary for
        evidentiary purposes or to respond to a evidential purposes or to meet a
        legal obligation.
      </p>

      <p>
        If the User wishes to know how
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> uses his Personal
        Data, ask to rectify them or object to their processing, the User
        processing, the User may contact.
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> in writing to the
        following address:
      </p>

      Bastien Rigaud - DPO, Bastien Rigaud <br />
      14 Rue Albert Pitres 33000 Bordeaux.

      <p>
        In this case, the User must indicate the Personal Data that he/she would
        like to be would like to have
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> correct, update or
        delete, by update or delete, identifying himself/herself precisely with
        a copy of an identification document (ID card or passport).
      </p>

      <p>
        Requests for deletion of Personal Data will be subject to the
        obligations that are imposed on
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> by law,
        particularly with respect to the retention or archiving of documents.
        Finally, Users of
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> may file a
        complaint to the supervisory authorities, and in particular to the CNIL
        (https://www.cnil.fr/fr/plaintes).
      </p>

      <h3>7.4 Non-disclosure of personal data</h3>

      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> will not process,
        host or transfer the Information collected on its Customers to a country
        outside the European Union or recognized as "non-adequate" by the
        European Commission without prior notice to the without prior notice to
        the customer. For all that,
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> remains free to
        choice of its technical and commercial subcontractors, provided that
        they that they present sufficient guarantees with regard to the
        requirements of the General Data Protection Regulation (RGPD: n°
        2016-679).
      </p>

      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> undertakes to take
        all necessary precautions to preserve the security of the Information
        and in particular that it is not communicated to unauthorized persons.
        However, if an incident occurs that affects the integrity or
        confidentiality of the Customer's Information is brought to the to the
        knowledge of <a href="https://www.teyz.fr">https://www.teyz.fr</a>, it
        shall inform the Customer as soon as possible and communicate the
        corrective corrective measures taken. In addition
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> does not collect
        any "sensitive data".
      </p>

      <p>
        User's Personal Data may be processed by subsidiaries of
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> and subcontractors
        (service providers), exclusively in order to achieve the purposes of
        this policy.
      </p>
      <p>
        Within the limits of their respective attributions and for the purposes
        recalled above, the main persons likely to have access to the data of
        Users of
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> are mainly our
        customer service agents.
      </p>

      <div ng-bind-html="rgpdHTML"></div>

      <h2>8. Incident Notification</h2>
      <p>
        No matter how hard you try, no method of transmission over the Internet
        and no method of electronic storage is completely secure. As a result,
        we cannot guarantee absolute security. If we become aware of a security
        breach, we will notify the users concerned so that they can take
        appropriate measures. appropriate action. Our incident notification
        procedures take into account our legal our legal obligations, whether at
        national or European level. national or European level. We are committed
        to fully informing our customers of all of all matters relating to the
        security of their account and to provide them with and to provide them
        with all necessary information to help them meet their own to help them
        meet their own regulatory reporting obligations. reporting.
      </p>
      <p>
        No personal information of the user of the site
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> is published to
        the user's knowledge, exchanged, transferred, assigned or sold on any
        medium to third medium to third parties. Only the hypothesis of the
        repurchase of <a href="https://www.teyz.fr">https://www.teyz.fr</a> and
        its rights would allow the transmission of the said information to the
        eventual purchaser, who would in turn be bound by the same obligation to
        preserve and obligation to preserve and modify the data with respect to
        the user of the of the site
        <a href="https://www.teyz.fr">https://www.teyz.fr</a>.
      </p>

      <h3>Security</h3>

      <p>
        To ensure the security and confidentiality of Personal Data and Personal
        Health Data,
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> uses networks
        protected by standard features such as firewalls, pseudonymization
        pseudonymization, encryption and password.
      </p>

      <p>
        When processing Personal Data,
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> takes all measures
        to protect it from loss, misuse, unauthorized access misuse,
        unauthorized access, disclosure, alteration or destruction.
      </p>

      <h2>9. Hypertext links "cookies" and internet tags ("tags")</h2>
      <p>
        The <a href="https://www.teyz.fr">https://www.teyz.fr</a> site contains
        a number of hypertext links to other sites, set up with the permission
        of <a href="https://www.teyz.fr">https://www.teyz.fr</a>. However,
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> does not have the
        possibility of verifying the content of the sites thus visited, and will
        consequently assume no responsibility for it.
      </p>
      Unless you decide to disable cookies, you agree that the site may use
      them. You can disable these cookies at any time and free of charge from
      the deactivation options that are offered to you and and recalled below,
      knowing that this may reduce or prevent access to all or part
      accessibility to all or part of the Services offered by the site.
      <p></p>

      <h3>9.1 "COOKIES"</h3>
      <p>
        A "cookie" is a small information file sent to the the User's browser
        and stored in the User's terminal (e.g., computer, computer User's
        terminal (e.g. computer, smartphone), (hereinafter "Cookies"). This file
        includes information such as the User's domain name, Internet service
        provider domain name, the User's Internet service provider, the User's
        operating system of the User, as well as the date and time of access.
        time of access. Cookies will in no way damage the User's terminal. of
        the User.
      </p>
      <p></p>
      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> is likely to
        process the User's information concerning his visit to the Site, such as
        the pages consulted, the searches performed. This information allows
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> to improve the
        content of the Site, of the User's navigation.
      </p>
      <p>
        Since Cookies facilitate navigation and/or the provision of services
        services offered by the Site, the User may configure his browser to
        allow him to to decide whether or not to accept them, so that Cookies
        are saved on the User's so that Cookies are stored in the terminal or,
        on the contrary, that they are rejected or, on the contrary, that they
        are rejected, either systematically, or according to their sender. The
        User may also configure his or her browser so that the software so that
        the acceptance or refusal of Cookies is proposed to him/her be proposed
        to him punctually, before a Cookie is likely to be to be recorded in his
        terminal.
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> informs the User
        that, in this case, it is possible that the functionalities of his/her
        browser software may not be fully available.
      </p>
      <p>
        If the User refuses the storage of Cookies in his terminal or or
        browser, or if the User deletes those stored there, the User stored
        there, the User is informed that his navigation and experience on the
        experience on the Site may be limited. This could also be the case when
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> or one of its
        providers cannot recognize, for technical compatibility purposes, the
        type of browser the type of browser used by the terminal, the language
        and display settings or the language and display settings or the country
        from which the terminal appears to be connected to the Internet.
      </p>
      <p>
        If applicable,
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> disclaims all
        responsibility for the consequences related to the degraded functioning
        of the Site and any services offered by
        <a href="https://www.teyz.fr">https://www.teyz.fr</a>, resulting from
        (i) the refusal of Cookies by the User (ii) the impossibility for
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> to save or to save
        or consult the Cookies necessary for their operation due to the User's
        choice. For the management of Cookies and the choices of the User, the
        configuration of each browser is different. It is described in the
        browser's help menu, which will allow to how the User can modify his
        wishes regarding Cookies. regarding Cookies.
      </p>
      <p>
        At any time, the User can make the choice to express and modify his/her
        wishes regarding Cookies.
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> may furthermore
        use the services of external service providers to assist in collect and
        process the information described in this section.
      </p>
      <p>
        Finally, by clicking on the icons dedicated to the social networks
        Twitter, Facebook, Linkedin and Google Plus appearing on the Site of
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> or in its mobile
        application and if the User has accepted the deposit of cookies by
        continuing to browse the Website or mobile application of
        <a href="https://www.teyz.fr">https://www.teyz.fr</a>, Twitter,
        Facebook, Linkedin and Google Plus may also deposit cookies on your
        terminals (computer, tablet, cell phone).
      </p>
      <p>
        These types of cookies are deposited on your terminals only if that you
        consent to them, by continuing your browsing on the Website Website or
        the mobile application of
        <a href="https://www.teyz.fr">https://www.teyz.fr</a>. At any time, the
        User may, however, revoke his or her consent to have
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> deposit this type
        of cookies.
      </p>

      <h3>Article 9.2. INTERNET TAGS</h3>

      <p>
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> may employ
        occasionally use Internet tags (also known as "tags", or action tags,
        single-pixel GIFs, transparent GIFs, invisible GIFs and GIFs) and deploy
        them through a web analytics partner that may analytics partner that may
        be located (and therefore store) the information information, including
        the User's IP address) in a foreign User's IP address) in a foreign
        country.
      </p>

      <p>
        These tags are placed both in the online advertisements advertisements
        that allow Internet users to access the Site, and on the various pages
        of the Site.
      </p>
      <p>
        This technology allows
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> to evaluate the
        responses of visitors to the Site and the effectiveness of its actions
        (e.g., the number of times a for example, the number of times a page is
        opened and the information accessed), as well as the User's use of this
        Site.
      </p>
      <p>
        The external service provider may collect information information about
        visitors to the Site and other websites through these tags, compile
        reports on the activity of the Site for the attention of
        <a href="https://www.teyz.fr">https://www.teyz.fr</a>, and provide other
        services relating to the use of this and the Internet.
      </p>
      <p></p>
      <h2>10. Applicable law and jurisdiction.</h2>
      <p>
        Any dispute in connection with the use of the site
        <a href="https://www.teyz.fr">https://www.teyz.fr</a> is subject to
        French law. Except in cases where the law does not allow it, it is
        exclusive jurisdiction to the competent courts of Bordeaux
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>
<style scoped lang="scss">
.termsOfServiceRoot {
  width: 100%;
  .container {
    padding: 80px 0;
    text-align: left;
    font-size: 120%;
    line-height: 120%;

    h2 {
      font-size: 26px;
      font-weight: 600;
      margin: 16px 0;
    }

    p {
      margin: 8px 0;
    }

    a {
      color: $secondary-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .container {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}
</style>
