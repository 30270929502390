<template>
  <HomeModule />
</template>

<script>
import HomeModule from "@/modules/Home";
export default {
  name: "Index",
  components: {
    HomeModule,
  },
  setup() {},
};
</script>

<style scoped lang="scss"></style>
