<template>
  <NotFoundModule />
</template>

<script>
import NotFoundModule from "@/modules/NotFound";
export default {
  name: "NotFound",
  components: {
    NotFoundModule,
  },
  setup() {},
};
</script>

<style scoped lang="scss"></style>
