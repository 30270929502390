<template>
  <GotagasModule />
</template>

<script>
import GotagasModule from "@/modules/Gotaga";
export default {
  name: "Gotaga",
  components: {
    GotagasModule,
  },
  setup() {},
};
</script>

<style scoped lang="scss"></style>
