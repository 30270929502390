export default [
  {
    "key": "brawks",
    "img": "img/clients/hardzone.webp",
    "size": "normal",
    "video": "media/Hardzone.webm",
    "name": "Hardzone",
    "project": "hardzone",
    "concept": "Every week brawks and mystk face off for the clash of the titans! Each is accompanied by a guest chosen beforehand for a kill race that promises to be epic! The guest duo is made up of either two streamers or two guests (public figures) from the same universe. The duos will compete in 6 warzone games in which they will have to face challenges to score as many points as possible and can be helped by the viewers for some missions.",
    "description": "The project consists in creating a webapp to display face-down cards in a first step. 6 cards are displayed, when clicking on one of them, it turns over and displays a challenge (Bonus / Malus etc.). The display is instantaneous on the stream.",
    "tags": "Vue 3, NodeJS",
    "link": "https://www.twitch.tv/brokybrawkstv",
    "index": 0
  },
  {
    "key": "brawks",
    "img": "img/clients/lagrossegame.webp",
    "size": "xlImage",
    "name": "La Grosse Game - Lucky Wheel",
    "project": "lggWheel",
    "description": "This project consists in the creation of a webapp containing 2 wheels of luck, which will be integrated on the stream during the shows.",
    "tags": "Php, JS",
    "link": "https://www.twitch.tv/brokybrawkstv",
    "index": 1
  },
  {
    "key": "brawks",
    "img": "img/clients/lagrossegame.webp",
    "size": "xlImage",
    "name": "La Grosse Game - Realtime Counter",
    "project": "lggCounter",
    "description": "This project consists in the creation of a webapp displaying a real time counter of 'kills'.",
    "tags": "NodeJs",
    "link": "https://www.twitch.tv/brokybrawkstv",
    "index": 2
  }
];