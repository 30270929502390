export default [
  {
    "src": "img/clients/gotaga.webp",
    "link": "https://www.twitch.tv/gotaga"
  },
  {
    "src": "img/clients/huskow.webp",
    "link": "https://www.twitch.tv/huskow"
  },
  {
    "src": "img/clients/brawks.webp",
    "link": "https://www.twitch.tv/brokybrawkstv"
  }
];