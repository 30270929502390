export default [
  {
    "key": "gotaga",
    "src": "img/zlan.webp",
    "text": "A simple NodeJs & React module to change game Logo for ZLAN event.",
    "link": "/gotaga"
  },
  {
    "key": "brawks",
    "src": "img/hardzone.webp",
    "text": "A Vue 3 website to display realtime cards on stream.",
    "link": "/brawks"
  }
];