<template>
  <TermsModule />
</template>

<script>
import TermsModule from "@/modules/Terms";
export default {
  name: "Terms",
  components: {
    TermsModule,
  },
  setup() {},
};
</script>

<style scoped lang="scss"></style>
