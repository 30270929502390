<template>
  <BrawksModule />
</template>

<script>
import BrawksModule from "@/modules/Brawks";
export default {
  name: "Brawks",
  components: {
    BrawksModule,
  },
  setup() {},
};
</script>

<style scoped lang="scss"></style>
